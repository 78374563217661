@import "../../../GlobalStyle/variables";
@import "../../../GlobalStyle/mixin";

.latest-trailer {
    .trailer-box-cotainer {
        padding:  $padding 0;
        @media (min-width:992px) {
            padding: $padding $padding-40;
        }
        background-color: rgba(11, 76, 119, 0.644);
        .trailer-header {
            @include flex-box(center,flex-start,10px);
            @media (max-width: 991px) {
                padding: 0 $padding;
            }
            flex-wrap: wrap;
            h3 {
                font-weight: 700;
                color: $text-light-color-2;
                font-size: $f-size; 

            }
            .trailer-nav {
                border-radius: $border-r-l;
                border: 1px solid $logo-color;
                border-bottom-color: transparent;
                overflow-y: auto;
                 .trailer-ul {
                    border-bottom: 1px solid $logo-color;
                     @include flex-box(center,flex-start,0);
                     min-width: fit-content;
                     
                     li {
                        padding: 3px $padding;
                        border-radius: $border-r-l;
                        color: $text-dark-color;
                        min-width: fit-content;
                        font-weight: 500;
                        color: $text-light-color-2;
                        &.active{
                            background-color: $logo-color;
                        }
                     }
                 }
            }
        }
        .trailer-content {
            overflow-x: auto;
            padding: 0 $padding;
            .trailer-container {
                min-width: fit-content;
                @include flex-box(flex-start,flex-start,20px);
                .trailer-media {
                    width: 300px;
                    z-index: 99999;
                    margin-top: $margin;
                    cursor: pointer;
                    &:hover {
                        filter: hue-rotate(20deg) brightness(120%);
                        transform: scale(1.03)
                    }
                    .trailer-image {
                        position: relative;
                        img {
                            width: 300px;
                            height: 180px;
                            border-radius: $border-r-l;
                            object-fit: fill;
    
                        }
                        .more-info {
                            position: absolute;
                            right: 20px;
                            top:20px;
                            background-color: #fff;
                            @include flex-box(center,center,0);
                            border-radius: 50%;
                            svg {
                                font-size: $f-size;
                            }
                        }
                        .pay-trailer {
                            position: absolute;
                            left: 50%;
                            top: 50%;
                            transform: translate(-50%,-50%);
                            svg {
                                color: $text-light-color;
                                font-size: 80px;
                            }
                        }
    
                    }

                    .trailer-titles {
                        text-align: center;
                        .name {
                            font-weight: 500;
                            color: $text-light-color-2; 
                            font-size: $f-size-4;
                        }
                        .tr-type {
                            color: $text-light-color;
                            font-size: $f-size-6;
                        }
                    }
                }
            }
        }

    }
}
