@import '../../../GlobalStyle/variables';
@import '../../../GlobalStyle/mixin';

.movie-stits {
    padding: $padding;
    .stits-container {
        .stits-nav {
            @include flex-box(center,flex-start,10px);
            padding: $padding 0;
            .social-links {
                height: 40px;
                width: 40px;
                img {
                    height: 30px;
                    width: 30px;
                    filter: grayscale(100%);   
                    &:hover {
                        transform: scale(1.05);
                        filter: grayscale(0);
                    }
                }
            }

        }
        .status {
            .stat {
                padding: $padding-10 0;
                h4 {
                    margin-bottom: 5px;
                }
                h5 {
                    font-weight: 400;
                }
            }
        }
        .network {
            .net-t {
                font-size: $f-size-5;
            }
            .net-images {
                padding: $padding-10 0;
                .network-image {
                    max-width: 150px;
                    margin-bottom: 5px;
                }
            }
        }
        .keywords {
            padding-bottom: $padding;
            .key-t {
                margin: 10px 0;
            }
            .keywords-ul {
                @include flex-box(center,flex-start,5px);
                flex-wrap: wrap;
                li {
                    padding: $padding-5 $padding-10;
                    border-radius: $border-r;
                    cursor: pointer;
                    font-size: $f-size-5;
                    border: 1px solid $logo-color;
                }
            }
        }
    }
}