@import "../../GlobalStyle/variables";
@import "../../GlobalStyle/mixin";

.movies {
    padding: $padding;
    @media (min-width: 992px) {
        padding: $padding $padding-40;
    }
    .filt-title {
        margin-bottom: $margin;
        font-size: $f-size-1;
    }
    .movies-container {
        &.web {
            @include flex-box(flex-start,flex-start,20px);
            @media (min-width:992px) {
                gap: 60px;
            }
            .sort {
                 width: 280px;
            }
        }
        .filters-box {
            min-width: fit-content;
        }
        .movies-content {
            @include flex-box(flex-start,flex-start,20px);
            flex-wrap: wrap;
            .not-found {
                width: 400px;
                height: calc(100vh - 100px);
                @include flex-box(center,center);
                .text {
                    font-size: $f-size-2;
                    color: $text-dark-color-2;
                }

            }
        }
    }
    .filter-btn {
        width: 100%;
        text-align: center;
        margin: 10px 0;
        padding: $padding-5;
        font-weight: 700;
        font-size: $f-size-3;
        border: 1px solid $logo-color;
        &:disabled{
            cursor:wait;
        }
        .loading-more {
            @include flex-box(center,center,5px);
            height: 35px;
            span {
                border-radius: $circle;
                width: 5px;
                height: 5px;
            }
            .s-1 {
                animation: loader 0.5s ease-in-out infinite alternate-reverse ;
            }
            .s-2 {
                animation: loader 0.5s  ease-in-out .5s infinite alternate-reverse ;
            }
            .s-3 {
                animation: loader 0.5s 1.5s ease-in-out 1s infinite alternate-reverse ;
            }
        }
    }
}

@keyframes loader {
    100% {
        width: 15px;
        height: 15px;
    }
}
