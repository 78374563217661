
@import "../../GlobalStyle/variables";
@import "../../GlobalStyle/mixin";



    .movie-container {
        .movie-content {
            @media (min-width:992px) {
                @include flex-box(flex-start,flex-start,20px);
                padding: 0 $padding-40;
                max-width: 100vw;
                .left-content {
                    width: 70%;
                }
                .right-content {
                    width: 30%;
                }
                
            }
        }
    }