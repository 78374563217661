@import "../../../GlobalStyle/variables";
@import "../../../GlobalStyle/mixin";

.backdrops {
    .backdrop-container {
        .back-content {
            display: grid;
            grid-template-columns: repeat(auto-fill,minmax(260px,1fr));
            grid-gap: 40px;
            .b-card {
                min-width: 280px;
                .b-image-cont {
                    min-height: 150px;
                    @include flex-box(center,center);
                    &:hover {
                        background-color: $header-b-ground-c;
                    }
                }
                .b-info {
                    .b-header {
                        @include flex-box(center,space-between);
                        padding: $padding-10;
                        border-bottom: 1px solid #00000021;
                        span {
                            font-size: $f-size-xs;
                        }
                        svg {
                            font-size: $f-size-s;
                        }
                    }
                    .b-body {
                        padding: $padding-10;
                        .size, .b-lang {
                            span {
                                font-size: $f-size-xs;
                            }
                            p {
                                font-size: $f-size-s; 
                                @include flex-box(center,flex-start,5px);
                                svg {
                                    font-size: $f-size-xs;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
