@import "../../../GlobalStyle/variables";
@import "../../../GlobalStyle/mixin";

.pers-acting {
    padding: $padding 0;
    .pers-acting-head {
        padding: $padding-10 0;
        @include flex-box(center,space-between);
        .act-t {
            font-size: $f-size-3;
        }
        .acting-nav {
            .acting-ul {
                @include flex-box(center,space-between);
                .act-li {
                    @include flex-box(center,flex-start,5px);
                }
            }
        }
    }
    .credits-tabel {
        width: 100%;
        .credits-tabel-body{
            .part {
                display: block;
                padding: $padding-10;
                .year {
                    width: 40px;
                }
                .cercle {
                    position: relative;
                 span {
                     width: 15px;
                     height: 15px;
                     display: block;
                     border: 2px solid $logo-color;
                     border-radius: 50%;
                     margin: 0 $margin;
                     position: relative;
                     cursor: pointer;
                     &:hover {
                        &::before {
                            content: "";
                            position: absolute;
                            width: 7px;
                            height: 7px;
                            background-color: $logo-color;
                            left: 2px;
                            top: 2px;
                            border-radius: 50%;

                        }
                     }
                   }
                }
                .movie-title {
                    span {
                        font-weight: 400;
                        font-size: $f-size-6;
                    }
                }
            }
        }
    }
}