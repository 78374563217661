@import "../../../GlobalStyle/variables";
@import "../../../GlobalStyle/mixin";

.episode-card {
    margin-bottom: $margin;
    .card-container {
        @media (min-width: 530px) {
            @include flex-box(flex-start,flex-start,10px);
        }
        .epis-image {
            max-height: 128px;
            @media (max-width: 531px) {
                width: fit-content;
                margin: 0 auto;
                
            }
            img {
                min-width:  227px;
                max-height: 128px;
            }
        }
        .epis-details {
            padding: $padding-10;
            .titles {
                .name {
                    font-size: $f-size-4;
                    @include text-overflow(30px);
                }
               @include flex-box(flex-start,center,5px);
               @media (min-width: 530px) {
                  justify-content: flex-start;
               }
               .rating-year  {
                @include flex-box(center,flex-start,10px);
                .rating {
                    @include flex-box(center,center,0);
                    background-color: $header-b-ground-c;
                    border-radius: $border-r;
                    padding: 0 $padding-5;
                    color: $text-light-color-2;
                    font-size: $f-size-6;
                    font-weight: bold;
                    span {
                        padding: 0 $padding-5;
                    }
                    svg {
                        font-size: $f-size-6;
                    }
                }
                .year {
                    font-size: $f-size-5;
                    font-weight: 600;
                    max-height: 25px;
                    overflow: hidden;
                }
            }
            }
            .overview {
                font-size: $f-size-6;
                font-weight: 600;
                padding-top: $padding-10;
                @include text-overflow(57px);
            }
        }
    }
    .expand {
        @include flex-box(center,center);
        padding: $padding-5 $padding;
        border-top: 1px solid  #6360603f ;
        .exp-link {
            @include flex-box(center,center,0);
        }
    }
}