@import "../../../GlobalStyle/variables";
@import "../../../GlobalStyle/mixin";
.reviews {
    .revi-content {
        @media (min-width: 720px) {
            padding: $padding $padding-40;
        }
         .review-card {
            margin-bottom: $margin;
         }
    }
}