@import '../../../GlobalStyle/variables';
@import '../../../GlobalStyle/mixin';

.movie-card {
    min-width: 150px;
    max-width: 150px;
    overflow: hidden;
    cursor: pointer;
    .image-container {
        position: relative;
        width: 150px;
        height: 225px;
        border-radius: $border-r-l;
        .poster {
            border-radius: $border-r-l;
            width: 150px;
        }
        .consensus, .more-btn  {
            @include flex-box(center,center);
            border-radius: $circle;
            color: $text-light-color;

        }
        .consensus {
            @include postion(absolute,34px,34px,calc(100% - 20px),10px);
            background-color: $text-dark-color;
            font-size: $f-size-xs;
        }
        .more-btn {
            @include postion(absolute,28px,28px,10px,112px);
            cursor: pointer;
            border: 1px solid $logo-color;
            svg {
                font-size: $f-size;
            }
            &:hover {
                background-color: $logo-color;
            }
        }
    }
    .content {
        margin-top: $margin;
        .title {
            font-size: $f-size-6;
            font-weight: 600;
        }
        .time {
            font-size: $f-size-6;
        }
    }
}