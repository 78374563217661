@import "../../../GlobalStyle/variables";
@import "../../../GlobalStyle/mixin";

.search-bar {
    background-size: cover;
    background-position: center ;
    .search-bar-container {
        padding: 60px $padding;
        color: $text-light-color;
        font-size: $main-f-size;
        text-transform: capitalize;
        background-color: #161515b7;
        @media (min-width: 992px) {
            padding: 60px $padding-40;
        }
        .welcome {
            font-size: $main-f-size + 10;
        }
    }
}
.search-form {
    width: 100%;
    margin-top: $margin + $margin;
    @include flex-box(center,space-between,$gap-l);
    input {
        flex: 1;
        padding: $padding - 5;
    }
    button {
        height: 100%;
        border: 1px solid $logo-color;
        font-size: $f-size-4;
        text-transform: capitalize;
        padding: $padding-5 $padding;
        border-radius: $border-r;
        margin: 0 10px;
    }
}