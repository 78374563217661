@import '../../../GlobalStyle/variables';
@import '../../../GlobalStyle/mixin';

.cover {
    background-size: cover;
    background-position: center;
    padding: $padding;
    @media (min-width: 992px) {
        padding: $padding $padding-40;
    }
    @include flex-box(center,flex-start);
    .flex-box {
      @include flex-box(center,flex-start,10px);
    }
    .cover-container {
        @media (min-width: 720px) {
            @include flex-box(flex-start,center,20px);
        }
        @media (min-width: 992px) {
            gap: $padding-40;
        }
        .cover-image {
            margin: 0 auto;
            width: fit-content;
            min-width: 300px;
            background-color: #ffffff3d;
            height: 450px;
            border-radius: $border-r;
            img {
                border-radius: $border-r;
            }
        }
        .cover-content {
            .titles {
                .name {
                 
                    @include text-overflow(38px);
                    font-size: $f-size-2;
                    color: $text-light-color;
                    @media (max-width: 719px) {
                        text-align: center;
                    }
                }
                .details {
                    span {
                        color: $text-light-color;
                        font-size: $f-size-5;
                    }
                }
            }
        }
        .links {
            margin: $margin - 5 0;
            @media (max-width:419px) {
                margin: 0;
                padding: $padding-5 0;
            }
            .user-score-container {
                display: none;
                @media (min-width: 867px) {
                    @include flex-box(center,space-between,5px);
                }
                .user-t {
                    font-size: $f-size-5;
                    color: $text-light-color;
                    display: none;
                    @media (min-width: 867px) {
                        display: flex;
                    }
                }
                .user-score  {
                    background-color: #0b316b3d;
                    color: $text-light-color;
                    border-radius: $circle;
                    @include flex-box(center,center);
                    width: 65px;
                    height: 65px;
                    border: 5px solid ;
                    border-color: $logo-color $logo-color $logo-color transparent;
                    cursor: pointer;
                    &:hover {
                        transform: scale(1.07);
                    }
                    h4 {
                        span {
                            font-size: $f-size-xs;
                        }
                    }
                }
            }
            .add-to {
                @media (max-width:419px) {
                    ul {
                        background-color: $header-b-ground-c;
                        @include postion(fixed,100%,fit-content,unset,0,99999999999999);
                        bottom: 0;
                        justify-content: space-evenly;
                    }
                    
                }
                ul {
                    li {
                        background-color: $header-b-ground-c;
                        color: $text-light-color;
                        padding: $padding-10;
                        border-radius: $circle;
                        cursor: pointer;
                        &:hover {
                            transform: scale(1.08);
                            }
                        }
                    }  
            }
            .play-trailer {
                gap: 0;
                &:hover {
                    transform: scale(1.03);
                }
                button{
                    cursor: pointer;
                    color: $text-light-color;
                    font-size: $f-size-5;
                    font-weight: 600;
                }
                svg {
                    color: $text-light-color;
                    font-size: $f-size-1; 
                }
            }
        }
        .overview {
            margin-bottom: $margin - 5;
            p {
                color: $text-light-color-2;
                font-style: italic;
            }
            h5 {
                font-size: $f-size;
                color: $text-light-color;
                margin:  $margin - 5 0;
            }
            aside {
                color: $text-light-color;
                font-size: $f-size-6;
                @media (min-width:720px) {
                    @include text-overflow(102px);
                    
                }
            }
        }
        .crews {
            display: grid;
            grid-template-columns: repeat(auto-fill,minmax(180px,1fr));
            gap: 20px;
            .crew {
               a {
                color: $text-light-color-2;
                font-size: $f-size-s;
                cursor: pointer;
                &:hover {
                    color: $text-light-color;
                }
               }
               aside {
                color: $text-light-color;
                font-size: $f-size-xs;
               }

            }
        }
    }
}