@import "./GlobalStyle/variables.scss";
@import "./GlobalStyle/mixin.scss";
@import "./GlobalStyle/colors";

@import url('https://fonts.googleapis.com/css2?family=IM+Fell+Double+Pica:ital@0;1&family=Iceberg&family=Kantumruy+Pro:ital,wght@0,100..700;1,100..700&display=swap');

body {
  font-family: "Kantumruy Pro", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
}
title {
  text-transform: capitalize !important;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  text-transform: capitalize;
  transition: all .3s;
  line-height: 1.3;
  scroll-behavior: smooth;
 
}

a {
  text-decoration: none;
}

li {
  list-style: none;
  cursor: pointer;
}
button {
  background-color: transparent;
}

input, input:focus, select{
  outline-color: transparent !important;
  border-color: transparent !important;
  outline: none;
  background-color: transparent ;
  cursor: pointer;
}
input {
  font-size: $f-size-5 !important;
}


img, picture {
  max-width: 100%;
  max-height: 100%;
  min-width: 100%;
  min-height: 100%;
  object-fit: cover;
  object-position: center;
}


.card {
    border-radius: $border-r;
    box-shadow: 0px 0px 9px -4px #8b8b8b61;
    overflow: hidden;
}
.alt-content {
      @media (min-width: 720px) {
         @include flex-box(flex-start,flex-start,20px);
      }
      @media (min-width: 992px) {
        gap: 30px;
        padding: $padding $padding-40;
        width: 100%;
      }
      .alt-cout-list {
            min-width: 260px;
            position: sticky;
            top: 0;
            z-index: 999;
            @media (min-width: 720px) {
                border-radius: $border-r;
                box-shadow: 0px 0px 9px -4px #8b8b8b;
                overflow: hidden;
            }
            @media (min-width: 720px) and (max-width:991px) {
              margin: $margin 0;
            }
            .cout-list {
              margin: $padding-10 0;
              max-height: calc(100vh - 130px);
              overflow-y: auto;
            }
            @media (max-width:719px) {
              .cout-list {
                @include flex-box(center,center,0);
                overflow-x: auto;
            }
        }
        .cout-header {
            padding: $padding;
            @include flex-box(center,space-between);
        }
      }
      .alt-t-tabels {
        @media (max-width:991px) {
          padding: $padding;
          
        }
          .titles-card {
              margin-bottom: $margin;
              min-width: 100%;
              display: inline-table;
              .t-h {
                  display: table;
                  padding: $padding-10;
                  min-width: 200%;
                  .count-name {
                    @include flex-box(center,flex-start,10px);
                    h3 {
                      font-size: $f-size-4;
      
                    }
                    .count-imge {

                      max-width: 30px;
                    }
                    img {
                      width: 30px;
                    }

                  }
              }
              .tbody {
                  .tr {
                    @include flex-box(center,flex-start,0);
                    padding: $padding-10;
                    &.tr-b-b {
                        border-bottom: 1px solid #dbd8d8;
                    }
                      td {
                          display: flex;
                          width: 50%;
                      }
                  }
              }
          }
      }
}

::-webkit-scrollbar {
  display: none;
}

.nav-btn {
    font-size: $f-size-5;
    font-weight: 700;
    padding: $padding-10 ;
    line-break: loose;
    min-width: fit-content !important;
  @include flex-box(center,space-between,5px);
    a {
        @include flex-box(center,space-between);
        width: 100%;
    }
  cursor: pointer;
  &.active,&:hover,&.active:hover {
      position: relative;
      transition: color 1s ;
      filter: brightness(60%);
      &::before {
          content: '';
          @include postion(absolute,0,0,100%,50%);
          border-radius: $circle;
          box-shadow: 0px -10px 36px 4px $logo-color;
          animation: animateActiveBTN .5s  ease-out forwards ;
      }
  }
  
}

@keyframes animateActiveBTN {
  100% {
      width: 100%;
      left: 0%;
  }
}

.image-hover {
  cursor: pointer;
    &:hover {
      filter:hue-rotate(20deg) brightness(120%)  ;
    } 
}

.scale {
    &:hover {
      transform: scale(1.03);
    }
}

.b-b {
  border-bottom: 1px solid #bbbbbb61;
  padding-bottom: $padding;
}
.b {
  border: 1px solid #bbbbbb61;
}





