@import "../../../GlobalStyle/variables";
@import "../../../GlobalStyle/mixin";

.vid-card {
    margin-bottom: $margin;
    .vid-cont {
        @media (min-width: 570px) {
            @include flex-box(_,_,0);
            max-height: 188px;
        }
        max-height: fit-content;
        flex: 1;
        .vid-image {
        max-height: fit-content;
        position: relative;
        min-width: 336px;
        min-height: 188px;
        @media (min-width:767px ) and (max-width: 992px){
            max-width: 230px;
            min-width: 230px;
            min-height: unset;
        }
        cursor: pointer;
        .play-icon {
            @include postion(absolute,60px,60px,50%,50%,9);
            transform: translate(-50%,-50%);
            border-radius: $circle;
            @include flex-box(center,center);
            background-color: rgba(0, 0, 0, 0.404);
            svg {
                color: $text-light-color-2;
                font-size: 50px;
            }
        }
    }
    .vid-info {
        @include flex-box(space-between,space-between,0);
        flex-direction: column;
        flex: 1;
        .title-date {
            padding: $padding-10;
            .name {
                font-size: $f-size-4;
                font-weight: 600;
                @include text-overflow(62px);
            }
            .d {
                font-size: $f-size-6;
            }
        }
        .yout-ch {
            padding: $padding-5 $padding-10 ;
            @include flex-box(center,flex-start,5px);
            span {
                font-size: $f-size-5;
                @include text-overflow(20px);
            }
            svg:nth-of-type() {
                font-size: 22px;
            }
            svg:nth-of-type(2) {
                font-size: 16px;
            }
        }
    }
  }
}