@import '../../GlobalStyle/variables';
@import '../../GlobalStyle/mixin';

.loading {
     min-width: 100%;
    .loading-container {
        @include flex-box(center,center);
        .spin {
            width: 50px;
            height: 50px;
            border: 4px solid;
            border-color:  #ada9a92d #a19f9f52 transparent transparent;
            border-radius: $circle;
            animation: spiner .8s  infinite forwards;
        }
    }
}

@keyframes spiner {
    100% {
        transform: rotate(180deg);
        border-color:  #8a858531;
    }
}