@import "../../../GlobalStyle/variables";
@import "../../../GlobalStyle/mixin";

.sort-content {
    .show-content {
        .option {
            label {
                padding: $padding-5;
            }
        }
    }
    .release-cont {
        .release-date {
            @include flex-box(center,space-between);
            input {
                border: 1px solid #dddadaab;
                padding: $padding-5;
                border-radius: $border-r;
                margin-bottom: 3px
            }
        }
    }
    .gen-nav {
        .genr-ul {
            @include flex-box(center,flex-start,5px);
            flex-wrap: wrap;
            li {
                padding: 3px $padding-10;
                cursor: pointer;
                font-size: $f-size-5;
                border-radius: $border-r;
                border: 1px solid #e0dbdb;
                &.active {
                    background-color: $text-dark-color;
                    color: $text-light-color;
                }
            }
        }
    }
}