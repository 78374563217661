@import "../../../GlobalStyle/variables";
@import "../../../GlobalStyle/mixin";

.main-t-header {
    padding: $padding ;
    @media (min-width: 992px) {
      padding: $padding $padding-40;
    }
    .media-details{
        @include flex-box();
        .media-image {
            max-height: 80px;
            overflow: hidden;
            border-radius: $border-r;
            img {
                max-height: 80px !important;   
            }
        }
        .media-back-to {
          .name {
              font-size: $f-size-2;
              font-weight: bold;
              @include text-overflow(33px);
              span {
                font-weight: 300;
                font-size: $f-size-4;
                border-radius: $border-r;
                padding: 0 $padding-5;
                line-height: 1;
                background-color: rgba(255, 255, 255, 0.116);
                margin-right: 5px
              }
          }
          a {
              @include flex-box(center,flex-start,5px);
              font-size: $f-size-xs;
              font-weight: 700;
              svg {
                  font-size: $f-size-s;
              }
              &:hover {
                filter: opacity(70%);
              }
          }
  
        }
  
    }
  
  }