@import "../../../GlobalStyle/variables";
@import "../../../GlobalStyle/mixin";

.epis-more-info {
    .ep-t,.ep-link {
        padding-bottom: $padding-10;
        font-size: $f-size-5;
    }
    .ep-link {
        font-size: $f-size-6;
    }
    .epis-nav {
        padding: $padding-10 $padding;
        border: 1px solid;
        border-color: #6360603f transparent;
        .epis-nav-ul {
            @include flex-box(center,space-between);
            overflow-x: auto;
        }
    }
    .epis-crew-guest {
        padding: $padding-10 $padding;
        @include flex-box(flex-start,space-between);
        flex-direction: column;
        @media (min-width:767px) {
                flex-direction: row;
        }
        .ep-guest {
            flex-basis: 75%;
            .ep-g-nav {
                @include flex-box(center,space-between);
            }
            .ep-guest-box {
                display: grid;
                grid-template-columns: repeat(auto-fill,minmax(400px,1fr));
            }
        }
    }
    .epis-images {
        padding: $padding;
        .ep-img-nav {
            @include flex-box(center,space-between);
        }
        .ep-images-box {
            @include flex-box(_,_,5px);
            overflow-y: auto;
            img {
                width: 250px;
                min-width: 250px;
            }

        }
    }
}