@import "../../../GlobalStyle/variables";
@import "../../../GlobalStyle/mixin";

.main-header {
    max-width: 100vw;
    .header-container {
        width: 100%;
        max-width: 100%;
        padding: $padding;
        @include flex-box(center,space-between,20px);
        position: relative;
        @media (min-width: 992px) {
            padding: $padding $padding-40;
        }
        .logo-nav {
            @include flex-box(center,flex-start,40px);
            .logo {
                min-width: fit-content;
                @include flex-box(center,flex-start,5px);
                flex: 1;
                cursor: pointer;
                h1 {
                    text-transform: uppercase;
                    color: $logo-color;
                    cursor: pointer;
                }
                .menu-icon {
                    font-size: $f-size-1;
                }
            }
        }
        .search-form {
            position: absolute;
            bottom: -50px;
            left: 0;
            z-index: 999;
            border-radius: 0;
            z-index: 99999;
        }
        button {
            border-radius: 34px;
            margin-right: 24px;

        }
    }
}