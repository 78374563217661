@import "../../../GlobalStyle/variables";
@import "../../../GlobalStyle/mixin";

.lang-container {
     position: relative;
}
.lang-box {
    @include postion(absolute,100%,fit-content,110%,0,99999999);
    padding: $padding-10 0;
    min-width: 200px;
    .lang-header {
        padding: $padding-10;
        .search-input {
            @include flex-box(center,space-between,0);
             border-radius: $border-r;
             border: 1px solid #9b9898cc;
             padding: $padding-5;
             width: 100%;
             input {
                width: 90%;
             }
             span {
                min-width: fit-content;
                display: block;
                svg {
                       font-size: $f-size-3;
                }
             }

        }
    }
    .lang {
        max-height: 250px;
        overflow-y: auto;
        .lang-ul {
            .lang-li {
                justify-content: flex-start;
                span {
                    width: 25px;
                    display: block;
                    img {
                        max-width: 25px;
                    }
                }
            }

        }
    }
}