@import "../../../GlobalStyle/variables";
@import "../../../GlobalStyle/mixin";

.trending {
    margin: $margin  0;
    @media (min-width: 992px) {
        padding: 0 $padding-40;
    }   
    .trending-container {
        .trend-header {
            @include flex-box(center,flex-start,10px);
            flex-wrap: wrap;
            @media (max-width: 991px) {
                padding: 0 $padding;
            }
            .trend-title {
                font-size: $f-size-2;
            }
            .trend-nav {
               ul {
                @include flex-box(center,flex-start,0);
                border-radius: $border-r-l;
                border: 1px solid $logo-color;
                li {
                    cursor: pointer;
                    font-weight: 500;
                    padding: 3px $padding;
                    border-radius: $border-r-l;
                    &.active{
                        background-color: $logo-color;
                        border: 1px solid $text-light-color;
                    }
                }
               }
            }
        }
        .movies {
           @include flex-box(flex-start,flex-start,10px);
           padding: $padding 0;
           overflow-x: auto;
           border-radius: $border-r-l;
           @media (max-width: 991px) {
            padding: $padding;
        }
        }
    }
}