@import "../../../GlobalStyle/variables";
@import "../../../GlobalStyle/mixin";

.cast {
    .cast-container {
        @include flex-box(flex-start);
        padding: 0 $padding;
        .cast-part {
            min-width: 50%;
            .main-t {
                font-size: $f-size;
                padding: $padding-10 0;
                span {
                    padding: 0 $padding-5;
                }
            }
        }
    }
}