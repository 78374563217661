@import "../../../GlobalStyle/variables";
@import "../../../GlobalStyle/mixin";

.person-cover {
    max-width: 100%;
    .per-cover-container {
        padding: $padding;
        @media (min-width:720px) {
            @include flex-box(flex-start,flex-start,20px);
            max-width: 100%;
            .person-info {
                min-width: 300px;
            }
            .person-cov-content {
              max-width: calc(100% - 300px);
            }
            
        }
        @media (min-width: 992px) {
           padding: $padding $padding-40;
           gap: 40px;
        }
        .person-img {
            max-width: 300px;
            margin: 0 auto;
            text-align: center;
            img {
                border-radius: $border-r;
            }
        }
        .person-name {
            font-size: $f-size-1;
            padding: $padding-5 0;
        }
        .person-cov-content {
            .piagrahpy {
                padding-bottom: $padding;
            }
            .pi-ti {
                font-size: $f-size;
            }
            .known-for {
                max-width: 100%;
                .kn-for-container {
                    padding: $padding 0;
                    overflow-x: auto;
                    @include flex-box(flex-start);
                }
            }
        }
    } 
}