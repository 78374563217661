@import "./variables";

@each $key, $val in $background-color {
    .back-color-#{$key} {
        background-color: $val;
    };

     @if($key == 'dark'){
         @for $i from 1 through 5 {
             .back-color-#{$key}-#{$i} {
                 background-color: mix(rgb(197, 191, 255),$val ,10 * $i);
             };
         };

     } @else {
         @for $i from 1 through 5 {
             .back-color-#{$key}-#{$i} {
                 background-color: mix(rgb(22, 0, 39),$val ,10 * $i);
             };
         };
     }
};

@each $key, $val in $text-color {
    .t-color-#{$key} {
        color: $val;
    };

    @if($key == 'light') {
        @for $i from 1 through 5 {
            .t-color-#{$key}-#{$i} {
                color: mix(white, $val,$i * 10);
            };
        };
    } @else {
        @for $i from 1 through 5 {
            .t-color-#{$key}-#{$i} {
                color: mix(black, $val,$i * 10);
            };
        };
    };
};

.link-color {
    font-size: $f-size-5;
    color: $logo-color;
    font-weight: 600;
    &:hover {
        filter: opacity(80%);
    }
}


.link-hover {
    &:hover {
      filter: opacity(80%);
    }
  }