
$header-b-ground-c: #032541;
$logo-color: #26bad3;
$text-light-color: #c7cbce;
$text-light-color-2: #f0f3f7;
$text-dark-color:#032541;
$text-dark-color-2:#0e3453;


$gap-l : 10px;
$padding: 20px;
$padding-40: 60px;
$padding-10: 10px;
$padding-5: 5px;
$margin: 20px;

$main-f-size: 28px;
$f-size: 22px;
$f-size-s: 18px;
$f-size-xs:14px;

// font sizes

$f-size-1: 1.8rem;
$f-size-2: 1.6rem;
$f-size-3: 1.4rem;
$f-size-4: 1.2rem;
$f-size-5: 1rem;
$f-size-6: .8rem;

$border-r: 5px;
$border-r-l: 10px;
$circle: 50%;

$background-color : (
    "dark" : #0b001d,
    "light" : #f6efff,
);

$text-color : (
    "dark" : #e8d9fa,
    "light" : #0e041f,
);