@import "../../../GlobalStyle/variables";
@import "../../../GlobalStyle/mixin";

.recommendations {
    .select-ul {
        @include flex-box(center,flex-start,0);
        @media  (max-width:991px){
            padding: 0 $padding;
        }
        li {
            font-size: $f-size-4;
        }
    }
    .recom-content {
          @include flex-box(flex-start);
          overflow-x: auto;
          padding: $padding-10  0;
          @media  (max-width:991px){
            padding: $padding-10 $padding;
        }
         .no-data-img {
            max-height: 350px;
         }
          .recomm-media-card {
            min-width: 250px;
            .recomm-image {
                cursor: pointer;
                position: relative;
                border-radius: $border-r;
                overflow: hidden;
                height: 141px;
                background-color: $text-light-color;
                img {
                    min-width: 250px;
                    min-height: 141px; 
                }
                .overlay-content {
                    @include postion(absolute,100%,fit-content,unset,0,99);
                    bottom: -100%;
                    padding: $padding-5;
                    display: none;
                    @include flex-box(center,space-between);
                    transition: all .5s;
                    .over-date, .add-to {
                        @include flex-box(center,center,5px);
                        svg {
                            font-size: $f-size-s
                        }
                    }
                }
                &:hover .overlay-content  {
                    
                    bottom: 0;
                }
            }
            .media-content {
                @include flex-box(center,space-between);
                .name {
                    font-size: $f-size-6;
                }
                padding: $padding-5 0;
            }
          }
    }
}