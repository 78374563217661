@import "../../../GlobalStyle/variables";
@import "../../../GlobalStyle/mixin";

.tv-content {
    max-width: 100vw;

    @media (min-width: 992px) {
        @include flex-box(flex-start,center,10px);
        padding: 0 $padding-40;
        .cast {
            width: 70%;
        }
        .right-content {
            width: 30%;
        }  
    }
}
.tv-season {
    padding: $padding;
    .sea-name {
        margin-bottom: $margin;
        font-size: $f-size-2;
    }
}