@import "../../../../GlobalStyle/variables";
@import "../../../../GlobalStyle/mixin";

.keywords-filter {
    position: relative;
    .key-box {
        padding: $padding-5;
        border: 1px solid $logo-color;
        border-radius: $border-r;
        &.true {
            border-color: #ffffff ;
        }
        .keys {
            @include flex-box(center,flex-start,5px);
            flex-wrap: wrap;
            li {
              @include flex-box(center,space-between,5px);
              border-radius: $border-r;;
              font-size: $f-size-xs;
              padding: $padding-5;
              svg {
                font-size: $f-size-xs;
              }
            }
        }
    }
    .auto-fill {
        position: absolute;
        left: 0;
        top: 100%;
        width: 100%;
        padding: $padding-10 0;
        .keys-ul {
            max-height: 150px;
            overflow-y: auto;
            li {
                 padding: $padding-5 $padding;
                 font-size: $f-size-6;
                 &.active {
                    background-color: $logo-color;
                    color: #fff;
                 }
            }

        }
    }
}