@import '../../../GlobalStyle/variables';
@import '../../../GlobalStyle/mixin';

.Movie-social {
    margin-bottom: $margin;
    .social-header {
        @include flex-box(center,space-between,50px);
        margin-bottom: 5px;
        @media (max-width:991px) {
            padding: 0 $padding; 
        }
        .social-title {
            font-size: $f-size;
        }
        .review {
            padding: $padding-10;
            font-size: $f-size-5;
        }
        
    }
    .no-result {
        padding: $padding;
    }
    .read-all-review  {
        display: block;
        padding: $padding;
    }
}