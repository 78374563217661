@import "../../../GlobalStyle/variables";
@import "../../../GlobalStyle/mixin";

.trailer-player {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh ;
    background-color: rgba(214, 214, 214, 0.863);
    z-index: 999999999;
    @include flex-box(center,center);
    .trialer-container {
        background-color: rgb(0, 0, 0);
        border-radius: $border-r;
        max-width: 100%;
        max-height: 100%;
        .tr-header {
            @include flex-box(center,space-between);
            padding: $padding-10;
            .off-tr {
                font-size: $f-size;
                color: $text-light-color;
            }
            .cancel {
                color: $text-light-color-2;
                cursor: pointer;
                svg {

                    font-size: $main-f-size;
                }

            }
        }
        .tr-player {
            max-width: 100%;
            .media-player {
                width: 800px;
                max-width: 100%;
            }
        }
    }
}