@import '../../../GlobalStyle/variables';
@import '../../../GlobalStyle/mixin';

.movie-media {
    margin: $margin 0;
    .movie-media-header {
        .media-nav {
            padding: $padding-10 0;
            @media (min-width: 630px) {
                 @include flex-box(center,flex-start,50px); 
             }
             .title {
                font-size: $f-size-3;
                @media (max-width: 991px) {
                    padding: 0 $padding;  
                }
             }
             .links {
                padding: 0 $padding-10;
                padding-bottom: $padding-5;
                @include flex-box(center,flex-start,0);
                overflow-y: auto;
             }
        }

    }
    .media-content {
        max-width: 100%;
        overflow-x:  auto;
        overflow-y: hidden;
        @media (max-width:991px) {
            padding: 0 $padding;
            border-radius: 0;
        }
        border-radius: $border-r;
        .media-content-container {
            min-width: fit-content;

            @include flex-box(flex-start,flex-start,0);
            .img-container {
                height: 200px;
                min-width: 150px; 
                img {
                        min-height: 200px;
                        max-height: 200px;
                    }
                    &.backdrops {
                        img {
                            min-width: 333px
                        }
                    }
                    &.posters {
                        img {
                            min-width: 150px
                        }
                    }
                }
            @media (min-width:530px) {
                .img-container {
                    min-height: 300px;
                    min-width: 200px; 
                    img {
                        min-height: 300px;
                        max-height: 300px;
                    }
                    &.backdrops {
                        img {
                            min-width: 533px
                        }
                    }
                    &.posters {
                        img {
                            min-width: 200px
                        }
                    }

                }
            }
            .view-more {
                @include flex-box(center,center);
                width: 150px;
                height: 200px;
            }

        }
            // video card
    .vid-card {
        border-radius: 0;
        border: none;
        min-height: 200px;
        min-width: 330px;
        .vid-image {
            min-height: 200px;
            min-width: 330px;
            img {
                min-height: 200px;
                min-width: 330px;
            }
        }
        @media (min-width: 530px) {
            min-height: 300px;
            min-width: 500px;
            .vid-image {
                min-height: 300px;
                min-width: 500px;
                img {
                    min-height: 300px;
                    min-width: 500px;
                }
            }
            
        }
        .vid-info {
            display: none !important;
        }
    }
    }
    .view-all {
        margin-top: $margin;
        display: block;
        @media (max-width: 991px) {
            padding: 0 $padding;
        }
    }
}
