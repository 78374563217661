
@import "../../../GlobalStyle/variables";

main.tv-seasons {
    .tv-s-container {
        .tv-seasons-content {
            padding: $padding;
            @media (min-width: 992px) {
                padding: $padding $padding-40;
            }
        }
    }
}