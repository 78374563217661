@import "../../GlobalStyle/variables";
@import "../../GlobalStyle/mixin";

.error {
    width: 100%;
    @include flex-box(center,center);
    .error-container {
        width: fit-content;
        text-align: center;
        .er-m {
            font-size: $f-size-4;
            margin-bottom: $margin;
        }
        .try-btn {
            font-size: $f-size-5;
            font-weight: 500;
            padding: $padding-5 $padding;
            border: 1px solid $logo-color;
            border-radius: $border-r;
        }
    }
}