
@import "../../../GlobalStyle/variables.scss";
@import "../../../GlobalStyle/mixin.scss";

.sort {
    margin-bottom: 10px;
    overflow: unset;
    .filter-t {
        @include flex-box(center,space-between);
        font-size: $f-size-5;
        padding: $padding-10 $padding;
        cursor: pointer;
      }
    .sort-content {
        padding: $padding;
        .c-ti {
            font-size: $f-size-5;
            font-weight: 400;
            padding-bottom: $padding-10;
        }
        .selections {
            cursor: pointer;
            @include flex-box(center,space-between);
            width: 100%;
            max-width: 100%;
            padding: $padding-10;
            font-weight: 400;
        }
        #selections * {
            padding: $padding-5;
        }
    }
}