@import "../../../GlobalStyle/variables";
@import "../../../GlobalStyle/mixin";

.person-card {
    border-radius: $border-r;
    overflow: hidden;
    width: 150px;
    min-width: 150px;
    max-width: 150px;
    height: 100%;
    cursor: pointer;
    .person-image {
       min-height: 225px;
       @include flex-box(center,center);
       background-color: #949599;
        img {
            max-width: 150px;
        }
   }
   .person-content {
    padding: $padding-10 0;
    .name {
        font-size: $f-size-6;
    }
    .char {
        font-size: $f-size-6;
    }
   }
}