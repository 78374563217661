@import "../../../GlobalStyle/variables";
@import "../../../GlobalStyle/mixin";

.key-card {
    @include flex-box(flex-start,flex-start,0);
    margin-bottom: $margin;
     .key-image {
        min-width: 100px;
        max-width: 100px;
        max-height: 150px;
         img {
            width: 100px;
         }
     }
     .card-details {
        padding: $padding-10 $padding;
         .key-title {
            h5 {
                @include text-overflow(19px);
            }
            .name {
                font-weight: 600;
                font-size: $f-size-5;
             }
             .date-re {
                 font-size: $f-size-6;
                 font-weight: 300;
                 padding-bottom:$padding-5 ;
 
             }
         }
         .key-overview {
             p {
                 @include text-overflow(81.88px);
                font-size: $f-size-6;
                font-weight:500;
             }
         }
     }
 }