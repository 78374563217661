@import "../../../GlobalStyle/variables";
@import "../../../GlobalStyle/mixin";

.movie-providers {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(45px,1fr));
    margin-top: $margin;
    gap: 10px;
    .provider-image {
        position: relative;
        cursor: pointer;
        img {
            cursor: pointer;
            border-radius: $border-r;
            height: 100%;
        }
        .overlay {
            @include postion(absolute);
            @include flex-box(center,center,0);
            border-radius: $border-r;
            background-color: rgba(8, 180, 180, 0.808);
            color: #fff;
            svg {
                font-size: $main-f-size;
            }
            &.name {
                display: none;
                width: 200px;
                max-width: fit-content;
                transform: translateY(-110%);
                height: unset;
                max-height: fit-content;
                padding: $padding-5;
                background-color: $text-dark-color;
                span {
                    min-width: fit-content;
                   word-break: keep-all;
                }
            }
        }
        &:hover .overlay.name{
            display: flex;
        }
    }

}