@import "../../../GlobalStyle/variables";
@import "../../../GlobalStyle/mixin";

.crew-card {
    @include flex-box(center,flex-start,10px);
    min-width: fit-content;
    max-height: 70px;
    margin-bottom: $margin;
    box-shadow: none;
    .crew-image {
        border-radius: $border-r;
        overflow: hidden;
        width: 70px;
        img {
            width: 70px;
            max-height: 70px;
        }
    }
    .crew-content {
        .name {
            font-size: $f-size-5;
            cursor: pointer;
        }
       }
}