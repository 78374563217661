@import "../../GlobalStyle/variables";
@import "../../GlobalStyle/mixin";

.collection-main {
    .coll-container {
        .collection-movie {
            padding: $padding;
            @media (min-width: 992px) {
                padding: $padding $padding-40;
            }
            .coll-header {
                padding-bottom: $padding;
                h4 {
                    color: $text-dark-color-2;
                    font-size: $f-size-4;
                }
            }
        }
    }
}