@import "../../../GlobalStyle/variables";
@import "../../../GlobalStyle/mixin";

.season-card {
    @include flex-box(flex-start,flex-start,10px);
    margin-bottom: $margin;
    max-height: 195px;
    cursor: pointer;
    .season-image {
        img {
            max-width: 130px;
            min-height: 195px;
        }
    }
    .season-details {
        padding: $padding-10;
        .s-titles {
            .s-name {
                font-size: $f-size-3;
                cursor: pointer;
            }
            .rating-year  {
                @include flex-box(center,flex-start,10px);
                .rating {
                    @include flex-box(center,center,0);
                    background-color: $header-b-ground-c;
                    border-radius: $border-r;
                    padding: 0 $padding-5;
                    color: $text-light-color-2;
                    font-size: $f-size-6;
                    font-weight: bold;
                    span {
                        padding: 0 $padding-5;
                    }
                    svg {
                        font-size: $f-size-6;
                    }
                }
                .year {
                    font-size: $f-size-5;
                    font-weight: 600;
                    @include text-overflow(24px);
                }
            }
        }
        .s-overview {
            .date {
                font-size: $f-size-5;
                font-weight: 600;
                margin: $padding-10 0;
                @include text-overflow(20px);
            }
            .overview {
                @include text-overflow(80px);
                font-size: $f-size-5;
            }
        }
    }
}