@import '../../../GlobalStyle/variables';
@import '../../../GlobalStyle/mixin';

.media-header {
    .media-nav {
        @include flex-box(center,center,10px);
        .med-title {
            position: relative;
            @include flex-box(center,center,0);
            cursor: pointer;
            color: rgb(131, 129, 129);
            font-weight: normal;
            padding:$padding-10;
            font-size: $f-size-s;
            .head {
                font-size: $f-size-5;
            }
            .links-list , .videos-ul {
                @include postion(absolute,150px,fit-content,45px,0,99999999);
                display: none;
                li {
                    &.videos {
                        position: relative;
                        .videos-ul {
                            top: 0;
                            left: 101%;
                            &.mobile {
                                left: 0;
                                top: 103%;
                            }
                        }
                        &:hover {
                            .videos-ul {
                                display: block;
                            }
                        }
                    }
                }
            }
            .links-list {
                &.last {
                    left: unset;
                    right: 0;

                }
            }
            &:hover {
                .links-list {
                    display: block;
                }
            }

        }
    }
}