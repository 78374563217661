@import "../../../GlobalStyle/variables";
@import "../../../GlobalStyle/mixin";

.person-stitis {
    .pers-stit-nav {
        @include flex-box(center,flex-start,10px);
        width: fit-content;
        margin: 0 auto;
        padding-top: $padding-10;
        .social-links {
            .social-image {
                width: 33px;
                height: 50px;
                img {
                    height: 30px;
                    width: 30px;
                }
            }
        }
    }
    h3 {
        margin-top: 20px;
    }
    .personal-info {
        padding: $padding-10 0;
        li {
           margin-bottom: $margin; 
           h4 {
            font-size: $f-size-s;
           }
           h6 {
            font-size: $f-size-s;
            font-weight: normal;
           }
        }
    }
    .kn-for {
        .for {
            font-size: $f-size-4;
        }
        .kn-for-ul {
            li {
                font-size: $f-size-6;
                font-weight: normal;
            }
        }
    }
}