
@mixin flex-box ($alIt: center,$jusFy:flex-start,$gap : 10px) {
    display: flex;
    align-items: $alIt;
    justify-content: $jusFy ;
    gap: $gap
}

@mixin postion ($pos,$width: 100%,$height: 100%,$top: 0,$left: 0,$z: 999){
    position: $pos;
    width: $width;
    height: $height;
    top: $top;
    left: $left;
    z-index: $z;
}

@mixin text-overflow ($max-h){
    max-height: $max-h;
    overflow: hidden;
}