@import "../../../../GlobalStyle/variables";
@import "../../../../GlobalStyle/mixin";

.rang {
    min-width: 200px;
    max-width: 200px;
    background-color: rgb(134, 129, 122);
    height: 6px;
    position: relative;
    margin: $margin 0;
    cursor: pointer;
    .reng-fill {
        height: 6px;
    }
    .reng-fill, .left, .right, .shows-rate-panel ,.gage{
        position: absolute;
        top:0;
        background-color: aqua;
        cursor: pointer;
    }
    .gage {
        background-color: unset;
        width: 100%;
        transform: translateY(-110%);
        @include flex-box(flex-end,center,0);
        li {
            width: calc(100% / 10);
            span {
                width: 1px;
                height: 5px;
                background-color: rgb(123, 125, 128);
                display: flex;
                cursor: pointer;
                &:hover {
                    width: 2px;
                    height: 6px;
                }
            }
            &.active{

                span {
                    height: 10px;
                }
            }
            &:last-of-type {
                @include flex-box(flex-end,space-between,0);
                span {
                    &:last-of-type {
                        height: 10px;

                    }

                }
            }
        }
    }
    .shows-rate-panel {
       transform: translate(-50%,-140% );
       left: 50%;
       border-radius: 5px;
       padding: $padding-5 $padding-10;
       min-width: fit-content;
    }
    .left, .right {
        cursor:grabbing;
        width: 20px;
        height: 20px;
        border-radius: $circle;
        transform: translate(-50%,-7px);
        &:hover {
            box-shadow: 0 0 5px 5px #00ffffa1;
            z-index: 9999;
        }
    }
}