@import "../../../GlobalStyle/variables";
@import "../../../GlobalStyle/mixin";

.review-card {
    padding: $padding;
    .review-header {
        @include flex-box(flex-start,flex-start,5px);
        margin-bottom: $margin;
        .img {
            min-width: 40px;
            min-height: 40px;
            max-width: 40px;
            max-height: 40px;
            overflow: hidden;
            border-radius: $circle;
        }
        .rev-titles {
            h4 {
                font-size: $f-size-4;
                font-weight:600; 
            }
            .date-rate{
                // @include flex-box(center,flex-start,5px);
                font-size: $f-size-6;

            }
        }
        
    }
    .review-content {
        .review {
            font-size: $f-size-6;
            font-weight: 500;
        }
    }
    
}