@import "../../GlobalStyle/variables";
@import "../../GlobalStyle/mixin";

main.search {
    .search-container {
        .search-results {
            .key-l {
                padding: $padding-5 0;
                .keyword-link  {
                    color: $text-dark-color-2;
                    font-size: $f-size-5;
                    font-weight: 500;
                }
            }
        }
    }
}