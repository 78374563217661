@import "../../../GlobalStyle/variables";
@import "../../../GlobalStyle/mixin";

.media-card {
    @include postion(absolute,400px,170px,unset,0,9999999999999);
    bottom: 40px;
    border-radius: $border-r;
    padding: $padding-10;
    &::before {
        content: "";
        position: absolute;
        bottom: -20px;
        border: 10px solid;
        border-color: $text-dark-color transparent transparent transparent;
    }
    &.en {
        transform: translateX(-70px);
        &::before {
            left: 88px;
        }
    }
    &.ar {
        transform: translateX(-270px); 
        &::before {
            left: 288px;
        }
    }
    .media-container {
        @include flex-box(flex-start);
        .image-box{
            max-height: 150px;
            max-width: 100px;
            min-width: 100px;
            border-radius: $border-r;
            overflow: hidden;
            
        }
        .media-details {
            nav.act-media {
                @include flex-box(center,flex-start,5px);
                .name {
                    font-size: $f-size-5;
                    font-weight: 700;
                    @include text-overflow(25px);
                }
                .act-vote {
                    background-color: $logo-color;
                    border-radius: $border-r;
                    @include flex-box(center,flex-start,0); 
                    padding:0 $padding-5;
                    svg {
                        font-size: $f-size-s;
                    }
                    p {
                        font-size: $f-size-xs;
                        padding:0 $padding-5;
                    }
                }
            }
            .overview {
                font-size: $f-size-6;
                line-height: 1.6;
                @include text-overflow(85px);
            }
            .add-to {
                @include flex-box(center,flex-start,10px);
                .add {
                    @include flex-box(center,center,0);
                    background-color: $logo-color;
                    border-radius: $border-r;
                    padding: $padding-10;
                    cursor: pointer;
                    svg {
                        font-size: $f-size-5;
                    }

                }
            }
        }
    }
}