@import "../../../GlobalStyle/variables";
@import "../../../GlobalStyle/mixin";

.top-billed {
    margin-bottom: $margin;
    padding: $padding-10 0; 
    .title {
        font-size: $f-size-2;
        padding-bottom: 0;
        @media (max-width: 991px) {
            padding: $padding;
        }  
    }
    .persons {
        @include flex-box(flex-start);
        overflow-x: auto;
        padding: $padding 0;
        @media (max-width: 991px) {
            padding: $padding-10 $padding;
        }  
    }
    .cast-link {
        @media (max-width: 991px) {
            padding: 0 $padding;
        }  
    }
    .view-more {
        min-width: 220px;
        min-height: 280px;
        padding: 0;
        @include flex-box(center,center);
        a {
            @include flex-box(center,center,0);
        }
    }
}